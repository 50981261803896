import React from "react"
import Layout from "../../../components/layout"
import P5Wrapper from '../../../components/sketchWrapper'

var drawCar = function (sketch, inputCar) {
	sketch.image(inputCar.img, inputCar.x, inputCar.y, 50, 50);
}
var drawCar2 = function (sketch, inputCar) {
	// sketch.point(infoCar.x, infoCar.y)
	var carLength = 40;
	var windowLength = 20;
	var carHeight = 10;
	var tireRadius = 10;
	var carOffset = 8;
	var hubcapRadius = 4;

	var infoCar = {
		x: inputCar.x - carLength / 2,
		y: inputCar.y - carHeight / 2,
		color: inputCar.color,
		pointSize: inputCar.pointSize
	}

	// draw drivers
	sketch.stroke(infoCar.color);
	sketch.strokeWeight(infoCar.pointSize);

	sketch.fill(infoCar.color);
	// car body
	sketch.rectMode(sketch.CENTER);
	sketch.rect(infoCar.x, infoCar.y, carLength, carHeight);

	// car roof
	sketch.noFill();
	sketch.strokeWeight(infoCar.pointSize + 2);
	sketch.arc(infoCar.x, infoCar.y, windowLength, carHeight * 2);
	// sketch.arc(infoCar.x - windowLength / 4, infoCar.y-5, windowLength, carHeight*2, sketch.PI, 0);

	/*
	sketch.strokeWeight(1);
	sketch.stroke('black');
	sketch.rect(infoCar.x+carLength/4, infoCar.y, (carLength/4)*2, carHeight);

	sketch.strokeWeight(infoCar.pointSize);

	// fill tires
	sketch.stroke("black");
	sketch.fill("black");
	sketch.ellipse(infoCar.x + carOffset, infoCar.y + carHeight, tireRadius);
	sketch.ellipse(infoCar.x + carLength - carOffset, infoCar.y + carHeight, tireRadius);

	// fill hub caps
	sketch.fill("white");
	sketch.ellipse(infoCar.x + carOffset, infoCar.y + carHeight, hubcapRadius);
	sketch.ellipse(infoCar.x + carLength - carOffset, infoCar.y + carHeight, hubcapRadius);
	*/
}
var sketchIndividualDistance = function (sketch) {
	var canvas = { width: 750, height: 200 }
	let imgCar;
	sketch.setup = function () {
		sketch.createCanvas(canvas.width, canvas.height);
	}
	sketch.preload = function() {
		imgCar = sketch.loadImage('/car.svg');
	}
	sketch.draw = function () {
		//for canvas 1
		sketch.background(255);

		var line = { color: 'blue', weight: 1 }
		var canvasline = { color: 'purple', weight: 1 }
		var pointSize = 20;
		var myLocation = { x: (canvas.width - pointSize / 2) / 2, y: (canvas.height - pointSize / 2) / 2, color: 'purple', pointSize }
		var driver1 = { x: 50, y: 75, color: 'green', pointSize: 1, img: imgCar }
		var driver2 = { x: 260, y: 35, color: 'green', pointSize: 1, img: imgCar }
		var driver3 = { x: 620, y: 45, color: 'green', pointSize: 1, img: imgCar }
		var driver4 = { x: 350, y: canvas.height / 2 + 65, color: 'green', pointSize: 1, img: imgCar }
		var driver5 = { x: 450, y: canvas.height / 2 + 45, color: 'green', pointSize: 1, img: imgCar }


		// draw canvas border
		sketch.stroke(canvasline.color);
		sketch.strokeWeight(canvasline.weight);
		sketch.line(0, 0, canvas.width, 0);
		sketch.line(0, 0, 0, canvas.height);
		sketch.line(canvas.width, canvas.height, canvas.width, 0);
		sketch.line(canvas.width, canvas.height, 0, canvas.height);


		// draw drivers
		var drivers = [driver1, driver2, driver3, driver4, driver5];
		for (var i = 0; i < drivers.length; i++) {
			var driver = drivers[i];
			sketch.stroke(line.color);
			sketch.strokeWeight(line.weight);
			sketch.line(myLocation.x, myLocation.y, driver.x + (driver.pointSize / 2), driver.y);

			drawCar(sketch, driver);
		}

		// draw me
		sketch.stroke(myLocation.color);
		sketch.strokeWeight(myLocation.pointSize);
		sketch.point(myLocation.x, myLocation.y)
	}
};


function sketch(p) {
	let canvas;

	p.setup = () => {
		canvas = p.createCanvas(300, 200);
		p.noStroke();
	}

	p.draw = () => {
		p.background('orangered');
		p.ellipse(150, 100, 100, 100);
	}

	p.myCustomRedrawAccordingToNewPropsHandler = (newProps) => {
		if (canvas) //Make sure the canvas has been created
			p.fill(newProps.color);
	}
}

var s1 = function (sketch) {
	sketch.setup = function () {
		sketch.createCanvas(100, 100, sketch.WEBGL);
	}
	sketch.draw = function () {
		//for canvas 1
		sketch.background(100);
		sketch.rotateX(sketch.frameCount * 0.01);
		sketch.rotateZ(sketch.frameCount * 0.01);
		sketch.cone(30, 50);
	}
};

var s2 = function (sketch) {

	sketch.setup = function () {
		sketch.createCanvas(100, 100);
	}
	sketch.draw = function () {
		//for canvas 2
		sketch.background(100);
		// sketch.rotateX(sketch.frameCount * 0.01);
		// sketch.rotateZ(sketch.frameCount * 0.02);
		// sketch.cone(30, 50);
	}
};


export default function Blog() {
	return (
		<Layout>
			<article className="blog">
				<header className="entry-header">
					<h1 className="entry-title">Introduction to Hilbert Curve</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">July 27, 2021</time>
					</div>
				</header>
				<div className="entry-content">
					<p>The other day I was geeking out watching a video on designing a food delivery app like Foodora or Uber Eats.  The presenter pose the problem "how do you quickly find all the food delivery drivers in your closest proximity?"</p>

					<p>You'll obviously need to use everyone's GPS coordinates.  But how do you figure out who is closest to you?</p>

					<p>Do you simply calculate the distance between you and each individual driver in the system?  What if they are constantly moving around?  If we have to calculate that for every single user and every single driver in the system, that won't be very fast or scalable!</p>

					<P5Wrapper sketch={sketchIndividualDistance}></P5Wrapper>

					<p>We could break our coverage map into regions, like postal codes or zip codes.  But then what if a user and a driver are close in proximity but they are in two different regions?  We would exclude a valid driver even if they were the close!  That's not cool.</p>

					<p>This would be so much simplier if we only had to deal with one single path.  Plot all the drivers and users onto a single path and then we can use a range query to find the suitable drivers.  But because this is GPS coordinates, there isn't just a single path. Or is there?</p>

					<p>Well, apparently, the math nerds like complex problems like this, and they figured out a way to take GPS coordinates and plot them onto a single path keeping the proximity information.</p>

					<p>How?</p>

					<p>Using a variety of continuous fractal space-filling curves.  The first described by David Hilbert and his <a href="https://en.wikipedia.org/wiki/Hilbert_curve">Hilbert Curve</a>.</p>

					<p>The Hilbert curve takes a continuous 1-dimensional line and fill a 2-dimensional space keeping the same proximity location in both 1D and 2D.  Since it's a fractal, no matter how small or large your region is, or how many subregions in a region, it will stay relatively the same shape and same proximity.  This is fantastic because if you found your region was too big and could be divided into subregions, you could easily do so and the location stays relatively the same.</p>

					<p>And then my mind was hooked and I follow some rabbit holes to learn how this all works.</p>

					<p>The Hilbert curve starts at first order by taking a region and dividing it into 4 quadrants.  It then draws a solid continuous line and connects each quadrant together</p>

					<P5Wrapper sketch={s2}></P5Wrapper>

					<p>At second order, it then takes that region and divides it into 4 additional quadrants and uses the first order shape to fill each quadrant.  And then rotates the bottom left shape clockwise and rotates the bottom right shape counter clockwise so that we have one continuous non-breaking line.</p>

					<p>At third order, it takes that region and divides it into 4 more quadrants and uses the second order shape to fill each quandrant.  Again rotating the bottom left and right, clock and counter-clockwise respectively.</p>


					{/*						
						The presenter starts with the various components to the system.  Use an api gateway here.  Use some microservices there.  And then came the complicated part.  How to figure out the closest providers by GPS coordinates.</p>
					<p>As the presenter explains, this is not an easy task</p>
					How to make range queries on a 2-d plane is difficult.
					Convert the GPS coordinates to a line, and now we can make a range query pretty fast.
					Take the line (1-d plane), preserve the proximity location and put it onto a 2-d plane.
					- z-curve
					- hilbert curve
					- alpha-curve?

					- calculating the distance of you to everyone else each time for each user is very time consuming.  So how do you take your position and their position and do a range query fast.


					<p>The presenter mentions Hilbert Curve.</p>
*/}
					<h2>Additional Resources</h2>

					<iframe width="560" height="315" src="https://www.youtube.com/embed/3s7h2MHQtxc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

					<iframe width="560" height="315" src="https://www.youtube.com/embed/OcUKFIjhKu0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

					<p>Happy Coding!</p>
				</div>
			</article>
		</Layout>
	)
}
